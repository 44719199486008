import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-lolcomponent",
  templateUrl: "./league-of-legends.component.html",
})
export class LolComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
