import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { PanelComponent } from "./layouts/app/app.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { LolComponent } from "./views/app/league-of-legends/league-of-legends.component";
import { ValorantComponent } from "./views/app/valorant/valorant.component";
import { SettingsComponent } from "./views/app/settings/settings.component";
import { TablesComponent } from "./views/app/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { SoonComponent } from "./views/soon/soon.component";

const routes: Routes = [
  // app views
  {
    path: "app",
    component: PanelComponent,
    children: [
      { path: "lol", component: LolComponent },
      { path: "valorant", component: ValorantComponent },
      { path: "settings", component: SettingsComponent },
      { path: "tables", component: TablesComponent },
      { path: "", redirectTo: "lol", pathMatch: "full" },
      { path: "**", redirectTo: "lol", pathMatch: "full" },
    ],
  },
  // // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "**", redirectTo: "login", pathMatch: "full" },
    ],
  },

  // no layout views
  { path: "", redirectTo: "soon", pathMatch: 'full' },
  { path: "soon", component: SoonComponent },
  { path: "dev_index@1867", component: IndexComponent },
  { path: "**", redirectTo: "soon", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
