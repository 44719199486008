import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { PanelComponent } from "./layouts/app/app.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// panel views
import { LolComponent } from "./views/app/league-of-legends/league-of-legends.component";
import { ValorantComponent } from "./views/app/valorant/valorant.component";
import { SettingsComponent } from "./views/app/settings/settings.component";
import { TablesComponent } from "./views/app/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { SoonComponent } from "./views/soon/soon.component";



// components for views and layouts
import { AppNavbarComponent } from "./components/navbars/app-navbar/app-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardHistoricLolComponent } from "./components/cards/card-historic-lol/card-historic-lol.component";
import { CardHistoricValorantComponent } from "./components/cards/card-historic-valorant/card-historic-valorant.component";
import { CardVsFiftyComponent } from "./components/cards/card-vsfifty/card-vsfifty.component";
import { FooterAppComponent } from "./components/footers/footer-app/footer-app.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

@NgModule({
  declarations: [
    AppComponent,
    LolComponent,
    ValorantComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAppComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardHistoricLolComponent,
    CardHistoricValorantComponent,
    CardVsFiftyComponent,
    HeaderStatsComponent,
    AuthNavbarComponent,
    AppNavbarComponent,
    PanelComponent,
    AuthComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    SoonComponent
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
